<template>
  <div class="nav-item" v-on="$listeners">
    <slot name="item">
      <router-link
        :to="to"
        :class="{
          'selected-route text-primary-100 font-bold bg-menuActive': isActive,
          'text-black hover:text-black': !isActive
        }"
        class="hover:bg-gray-100 flex items-center font-medium text-default pl-4 py-4"
      >
        <div :class="{'text-fonctGrey': !isActive }" v-if="icon">
          <component :is="icon" />
        </div>
        <span class="ml-3">{{ title }}</span>
      </router-link>
      <hr style="color: #C6D6EC" v-if="divide" />
    </slot>
  </div>
</template>

<script>
import Home from "@/assets/icons/home.svg";
import Copy from "@/assets/icons/copy.svg";
import User from "@/assets/icons/user.svg";
export default {
  components: {
    Home, Copy, User
  },

  props: {
    name: {
      type: String,
      default: ""
    },

    title: {
      type: String,
      default: ""
    },

    icon: {
      type: String,
      default: ""
    },

    to: {
      type: String,
      default: ""
    },
    divide: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isActive() {
      return this.$route.name === this.name;
    }
  }
}
</script>

<style>

</style>