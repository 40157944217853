<template>
  <div class="side-bar flex h-full min-h-958 max-w-243 w-full">
    <aside class="w-full">
      <div class="logo p-6 w-full">
        <BniLogo />
      </div>
      <div class="side-menu flex flex-col h-full">
        <v-nav-item
          v-for="(route, index) of item"
          :name="route.name"
          :icon="route.icon"
          :to="route.path"
          :title="route.title"
          :key="index"
        />
        <!-- <v-nav-item title="Déconnexion" icon="Home" @click.prevent="logout">
          
        </v-nav-item> -->
      </div>
    </aside>
  </div>
</template>

<script>
import VNavItem from './VNavItem.vue'
import BniLogo from '@/assets/icons/logo.svg'
export default {
  components: { VNavItem, BniLogo },

  methods: {
    logout(){
      this.$store.dispatch('auth/logout')
    }
  },

  computed: {
    item() {
      const item = this.$router.options.routes[0].children;
      console.log("item ", item);

      return item;
    }
  }
}
</script>

<style>

</style>