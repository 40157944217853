<template>
  <div class="navbar bg-primary-100 py-3.5">
    <!-- Desktop NavBar -->
    <div class="flex justify-end pr-6">
        <div class="relative text-sm" >
            <div class="flex items-center space-x-2 cursor-pointer text-white" @click="drop = !drop">
                <p class="">
                    <img v-if="user.profile" class="h-8 w-8 rounded-full" :src="fileUrl+`${user.profile.name}`" alt="">
                    <span v-else class="h-14 w-14 rounded-full">
                        <svg width="40" height="40" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="20" cy="20" r="20" fill="#CCC" />
                            <path d="M23.5924 11.0272C22.6802 10.0423 21.4062 9.5 19.9999 9.5c-1.4137 0-2.692.5391-3.6 1.5178-.9178.9895-1.365 2.3344-1.26 3.7866.2081 2.865 2.3883 5.1956 4.86 5.1956 2.4717 0 4.6481-2.3302 4.8596-5.1947.1064-1.4391-.3436-2.7811-1.2671-3.7781zM28.25 30.5h-16.5a1.4545 1.4545 0 01-.6261-.1328 1.4547 1.4547 0 01-.5083-.3889c-.3046-.3642-.4275-.8616-.3365-1.3645.3956-2.1947 1.6303-4.0383 3.5709-5.3325C15.5741 22.1323 17.758 21.5 20 21.5c2.2421 0 4.426.6328 6.15 1.7813 1.9407 1.2937 3.1753 3.1373 3.571 5.332.0909.503-.0319 1.0003-.3366 1.3645A1.4534 1.4534 0 0128.25 30.5z" fill="#000" />
                        </svg>
                    </span>
                </p>
                <p class="">
                  <span class="font-semibold">{{user.lastName }} {{ user.firstName}}</span><br>
                  <span>{{user.roles[0].name === 'admin' ? 'Administrateur': ''}}</span>
                </p>
                <p>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15.8751 9.00001L11.9951 12.88L8.1151 9.00001C7.92827 8.81275 7.67462 8.70752 7.4101 8.70752C7.14558 8.70752 6.89193 8.81275 6.7051 9.00001C6.3151 9.39001 6.3151 10.02 6.7051 10.41L11.2951 15C11.6851 15.39 12.3151 15.39 12.7051 15L17.2951 10.41C17.6851 10.02 17.6851 9.39001 17.2951 9.00001C16.9051 8.62001 16.2651 8.61001 15.8751 9.00001Z" fill="white"/>
                    </svg>
                </p>
            </div>
            <div v-if="drop" class="absolute w-full right-0 bg-white rouded-lg shadow-md p-6 flex flex-col space-y-2">
                <router-link to="/profil">
                    <p @click="drop = false">Profil</p>
                </router-link>
                <hr class="bg-borderColor">
                <p class="cursor-pointer" @click="logout">Déconnexion</p>
            </div>
        </div>
    </div>

    <!-- Mobile and tablette NavBar -->
    <div class="mobile">
      <div class="py-4 flex lg:hidden">
        <div class="logo mr-7" @click="open = ! open">
          <MenuIcon />
        </div>
        <div class="logo">
          <BniLogo />
        </div>
        <!-- <div class="user ml-auto mr-4">
          <div class="flex justify-center items-center">
            <span class="text-red-500">3</span>
            <img src="/avatar.png" />
            <div class="name">Jean</div>
          </div>
        </div> -->
      </div>
      <mobile-menu v-if="open" @close="open = false" />
    </div>
  </div>
</template>

<script>
import MobileMenu from './MobileMenu.vue'
import MenuIcon from '@/assets/icons/menu.svg'
import BniLogo from '@/assets/icons/logo.svg'
export default {
  components: { MobileMenu, MenuIcon, BniLogo },
  data(){
    return {
      fileUrl: process.env.VUE_APP_BACKEND_URL+'files/download/',
      open: false,
      drop: false,
    }
  },
  computed: {
    user(){
        return this.$store.getters['auth/authUser']
    }
  },

  methods: {
    logout () {
      this.$store.dispatch('auth/logout')
    }
  }
}
</script>

<style>

</style>