<template>
  <div class="side-bar bg-white fixed w-full h-full z-50 -mt-4">
    <aside class="mt-6 w-full">
      <div class="side-menu flex flex-col h-full">
        <v-nav-item
          v-for="(route, index) of item"
          :name="route.name"
          :icon="route.icon"
          @click="routeTo(route.path)"
          :title="route.title"
          :key="index"
        />
        <v-nav-item title="Déconnexion" icon="Home" @click.prevent="logout">
        </v-nav-item>
      </div>
    </aside>
  </div>
</template>

<script>
import VNavItem from './VNavItem.vue';
export default {
  components: { VNavItem },
  computed: {
    item() {
      const item = this.$router.options.routes[0].children;

      return item;
    }
  },

  methods: {
    routeTo(path){
      this.$router.push({path})
      this.$emit('close')
    },
    logout(){
      this.$store.dispatch('auth/logout')
    }
  }
}
</script>

<style>

</style>