<template>
  <div class="layout">
    <div class="flex">
      <SideBar class="hidden lg:block" />
      <div class="w-full bg-bgGrey">
        <NavBar />
        <router-view > </router-view>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue';
import SideBar from '@/components/SideBar.vue';
export default {
  components: {
    NavBar,
    SideBar
  }
}
</script>

<style>

</style>